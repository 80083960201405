<template>
  <div id="farshad" style="padding-bottom: 10em">
  <router-view></router-view>
  <MainMenu/>
  </div>
</template>

<script>
import MainMenu from "@/components/MainMenu.vue";

export default {
  name: 'App',
  components: {
    MainMenu
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Satisfy&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
#app {
  font-family: 'Indie Flower', cursive,Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
H1 {
  font-family: 'Great Vibes', cursive,Avenir, Helvetica, Arial, sans-serif;
}

.goldtext{
 color:#FFD700;
}
</style>
