<template>
  <div class="container">
    <H1 v-html="$t('Special-offer-title')"></H1>
    <div class="row" style="text-align: left;">
      <div class="col-12">
        <p v-html="$t('Special-offer-page-desc')"></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <img src="@/assets/img/springbloom1.jpg"  class="info-wrap w-100 p-5 img" alt=""/>
      </div>
      <div class="col-md-4">
        <img src="@/assets/img/springbloom2.jpg"  class="info-wrap w-100 p-5 img" alt=""/>
      </div>
      <div class="col-md-4">
        <img src="@/assets/img/springbloom3.jpg"  class="info-wrap w-100 p-5 img" alt=""/>
      </div>
    </div>
    <div class="row" style="text-align: left;">
      <div class="col-12">
        <p v-html="$t('book-with-phone')"></p>
      </div>
    </div>
  </div>
</template>
<script setup>
</script>
