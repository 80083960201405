<template>
  <div class="container">
    <H1 class="display-1">PaderFoto</H1>
    <hr/>
    <div class="row">
      <div class="col-sm-4">
        <p class="lh-lg"  v-html="$t('introduction')"></p>
      </div>
      <div class="col-sm-8">
        <Polaroid caption="event-photography" photo-name="portrait-thumb_IMG_9700-Edit.jpg" to-this-page="portfolio/event"></Polaroid>
        <img alt="" src=""/>
      </div>
    </div>
    <hr/>
    <div class="row">
      <div class="col-sm-6">
        <h2><span v-html="$t('News-title')"></span></h2>
        <div class="card" style="width: 100%;">
          <img src="@/assets/img/osterlauf1.jpg" class="card-img-top" alt="...">
          <div class="card-body">
            <h5 class="card-title" v-html="$t('News-Card-title')"></h5>
            <p class="card-text" v-html="$t('News-Card-body')"></p>
<!--            <a href="#" class="btn btn-outline-secondary" v-html="$t('News-Card-btn')"></a>-->
            <router-link  class="btn btn-outline-secondary" to="/gallery/osterlauf2024">{{ $t('News-Card-btn') }}</router-link>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <h2><span v-html="$t('Special-offer-title')"></span>Special offer</h2>
        <div class="card" style="width: 100%;">
          <img src="@/assets/img/springbloom3.jpg" class="card-img-top" alt="...">
          <div class="card-body">
            <h5 class="card-title" v-html="$t('Special-offer-Card-title')"></h5>
            <p class="card-text" v-html="$t('Special-offer-Card-body')"></p>
            <router-link  class="btn btn-outline-secondary" to="/specialOffer">{{ $t('Special-offer-Card-btn') }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <hr/>
    <div class="row">
      <div class="col-md-12">
        <h3 v-html="$t('our-services')"></h3>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <Polaroid caption="event-photography" photo-name="event-thumb_IMG_4726.jpg" to-this-page="portfolio/event"></Polaroid>
      </div>
      <div class="col-sm-6">
        <Polaroid caption="portrait-photography" photo-name="portrait-thumb_IMG_9700-Edit.jpg" to-this-page="portfolio/portrait"></Polaroid>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <Polaroid caption="wedding-photography" photo-name="paderborn-thumb_IMG_5513.jpg" to-this-page="portfolio/hochzeit"></Polaroid>
      </div>
      <div class="col-sm-6">
        <Polaroid caption="baptism-photography" photo-name="taufe-thumb_IMG_9727.jpg" to-this-page="portfolio/taufe"></Polaroid>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <Polaroid caption="birthday-photography" photo-name="geburtstag-thumb_IMG_4508-Editt.jpg" to-this-page="portfolio/geburtstag"></Polaroid>
      </div>
      <div class="col-sm-6">
        <Polaroid caption="pet-photography" photo-name="tier-thumb_8S5A1780-Edit.jpg" to-this-page="portfolio/tier"></Polaroid>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <Polaroid caption="product-photography" photo-name="produkt-thumb_IMG_4478.jpg" to-this-page="portfolio/produkt"></Polaroid>
      </div>
      <div class="col-sm-6">
        <Polaroid caption="fine-art-photography" photo-name="paderborn-thumb_IMG_5513.jpg" to-this-page="portfolio/kunst"></Polaroid>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <Polaroid caption="bodybuilding-photography" photo-name="paderborn-thumb_IMG_5513.jpg" to-this-page="portfolio/bodybuilding"></Polaroid>
        </div>
        <div class="col-sm-6">
          <Polaroid caption="kinder-photography" photo-name="kinder-thumb_IMG_4508-Editt.jpg" to-this-page="portfolio/kinder"></Polaroid>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <Polaroid caption="paderborn-city-photography" photo-name="paderborn-thumb_IMG_5513.jpg" to-this-page="portfolio/paderborn"></Polaroid>
        </div>
        <div class="col-md-6">
          <Polaroid caption="party-photography" photo-name="party-thumb_IMG_7464.jpg" to-this-page="portfolio/party"></Polaroid>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <Polaroid caption="sport-photography" photo-name="paderborn-thumb_IMG_5513.jpg" to-this-page="portfolio/sport"></Polaroid>
      </div>
      <div class="col-md-6">

      </div>
    </div>

    <hr>
    contact Us
  </div>

</template>
<style>

a {
  text-decoration: none !important;
}

/* Prevent color change on clicked links */
a:visited {
  color: inherit;
}

.wrapper {
  width: 100%;
  padding: 0 2rem;
  text-align: center;
}
.polaroid {
  background: #fff;
  padding: 1rem;
  box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);

}
.polaroid > img{
  max-width: 100%;
  height: auto;
}
.caption {
  font-size: 1.8rem;
  text-align: center;
  line-height: 2em;
}
.item {
  width: 65%;
  display: inline-block;
  margin-top: 0.3rem;
  filter: grayscale(100%);
}
.item .polaroid:before {
  content: '';
  position: absolute;
  z-index: -1;
  transition: all 0.35s;
}
.item:nth-of-type(4n+1) {
  transform: scale(0.8, 0.8) rotate(5deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+1) .polaroid:before {
  transform: rotate(6deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  right: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.4);
}
.item:nth-of-type(4n+2) {
  transform: scale(0.8, 0.8) rotate(-5deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+2) .polaroid:before {
  transform: rotate(-6deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  left: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.4);
}
.item:nth-of-type(4n+4) {
  transform: scale(0.8, 0.8) rotate(3deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+4) .polaroid:before {
  transform: rotate(4deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  right: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.3);
}
.item:nth-of-type(4n+3) {
  transform: scale(0.8, 0.8) rotate(-3deg);
  transition: all 0.35s;
}
.item:nth-of-type(4n+3) .polaroid:before {
  transform: rotate(-4deg);
  height: 20%;
  width: 47%;
  bottom: 30px;
  left: 12px;
  box-shadow: 0 2.1rem 2rem rgba(0,0,0,0.3);
}
.item:hover {
  filter: none;
  transform: scale(1, 1) rotate(0deg) !important;
  transition: all 0.35s;
}
.item:hover .polaroid:before {
  content: '';
  position: absolute;
  z-index: -1;
  transform: rotate(0deg);
  height: 90%;
  width: 90%;
  bottom: 0%;
  right: 5%;
  box-shadow: 0 1rem 3rem rgba(0,0,0,0.2);
  transition: all 0.35s;
}
</style>
<script>
import {defineComponent} from "vue";
import Polaroid from "@/components/Polaroid.vue";

export default defineComponent({
  components: {Polaroid}
})
</script>


