<template>
  <div class="menu">
    <ul>
      <li><router-link to="/">Home</router-link></li>
      <li><router-link to="/portfolio">Portfolio</router-link></li>
      <li><router-link to="/contact">Contact</router-link></li>
      <li><router-link to="/prices">{{ $t('prices') }}</router-link></li>
      <li><router-link to="/impressum">{{ $t('imprint') }}</router-link></li>
    </ul>
    <div class="d-none d-md-block">
      <button class="languagebtn" @click="changeLocale('en')">en</button>
      |
      <button class="languagebtn" @click="changeLocale('de')">de</button>
    </div>
  </div>
  <!--    for mobile version -->
  <div class="d-md-none">
  <div class="menu2" >
    <ul>
      <li><button class="languagebtn" @click="changeLocale('en')">en</button></li>
      <li><button class="languagebtn" @click="changeLocale('de')">de</button></li>
    </ul>
    <span style="float: right !important; margin-right: 15px;">LOGIN</span>
  </div>
  </div>
</template>

<style>

/* Basic styles for the menu */
.menu {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #a0a0a0;
  color: #fff;
  width: 100px; /* Set your desired width for desktop view */
  height: 100%;
  overflow-y: auto; /* Enable scrolling for menu items */
  transition: transform 0.3s ease-in-out;
  border-right: solid 1px #FFD700;
}

.menu ul {
  list-style-type: none;
  padding: 0;
}

.menu li {
  padding: 10px;
  text-align: center;
}

.menu a {
  text-decoration: none;
  color: #fff;
  display: block;
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .menu {
    position: fixed;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    bottom: 50px; /* Stick the menu to the bottom of the screen */
    top: auto;
    border-right: none;
  }

  .menu2 {
    background-color: #a0a0a0;
    position: fixed;
    width: 100%;
    height: 50px;
    margin-top: 50px;
    flex-direction: row;
    justify-content: space-around;
    bottom: 0; /* Stick the menu to the bottom of the screen */
    top: auto;
  }
  .menu li {
    flex: 1; /* Distribute available space equally among menu items */
    text-align: center;
    float: left;
  }

  .menu2 ul {
    list-style-type: none;
    padding: 0;
  }

  .menu2 li {
    padding: 10px;
    flex: 1; /* Distribute available space equally among menu items */
    text-align: center;
    float: left;
  }

  .menu2 a {
    text-decoration: none;
    color: #fff;
    display: block;
  }

  .languagebtn{
    border : 1px solid black;
    background-color: #ccc;
    color: #000;
  }
}

</style>

<script>
export default {
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
    },
  },
}
</script>
