
<template>

  <h1>portfolio</h1>
  <template v-if="hasId">
<!--    <p>API Response: {{ apiData }}</p>-->
    <!-- Add other content based on the API response -->
    <div>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <p v-html="$t($route.params.name+'-exp')"></p>
          </div>
      </div>
      </div>
      <hr>
      <PhotoGallery galleryID="my-test-gallery" :photos="photosData"  />
    </div>
    <hr>
  </template>
  <template v-else>
    <div class="row">
      <div class="col-sm-6">
        <Polaroid caption="event-photography" photo-name="event-thumb_IMG_4726.jpg" to-this-page="portfolio/event"></Polaroid>
      </div>
      <div class="col-sm-6">
        <Polaroid caption="portrait-photography" photo-name="portrait-thumb_IMG_9700-Edit.jpg" to-this-page="portfolio/portrait"></Polaroid>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <Polaroid caption="wedding-photography" photo-name="paderborn-thumb_IMG_5513.jpg" to-this-page="portfolio/hochzeit"></Polaroid>
      </div>
      <div class="col-sm-6">
        <Polaroid caption="baptism-photography" photo-name="taufe-thumb_IMG_9727.jpg" to-this-page="portfolio/taufe"></Polaroid>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <Polaroid caption="birthday-photography" photo-name="geburtstag-thumb_IMG_4508-Editt.jpg" to-this-page="portfolio/geburtstag"></Polaroid>
      </div>
      <div class="col-sm-6">
        <Polaroid caption="pet-photography" photo-name="tier-thumb_8S5A1780-Edit.jpg" to-this-page="portfolio/tier"></Polaroid>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <Polaroid caption="product-photography" photo-name="produkt-thumb_IMG_4478.jpg" to-this-page="portfolio/produkt"></Polaroid>
      </div>
      <div class="col-sm-6">
        <Polaroid caption="fine-art-photography" photo-name="paderborn-thumb_IMG_5513.jpg" to-this-page="portfolio/kunst"></Polaroid>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <Polaroid caption="bodybuilding-photography" photo-name="paderborn-thumb_IMG_5513.jpg" to-this-page="portfolio/bodybuilding"></Polaroid>
        </div>
        <div class="col-sm-6">
          <Polaroid caption="kinder-photography" photo-name="kinder-thumb_IMG_4508-Editt.jpg" to-this-page="portfolio/kinder"></Polaroid>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <Polaroid caption="paderborn-city-photography" photo-name="paderborn-thumb_IMG_5513.jpg" to-this-page="portfolio/paderborn"></Polaroid>
        </div>
        <div class="col-md-6">
          <Polaroid caption="party-photography" photo-name="party-thumb_IMG_7464.jpg" to-this-page="portfolio/party"></Polaroid>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <Polaroid caption="sport-photography" photo-name="paderborn-thumb_IMG_5513.jpg" to-this-page="portfolio/sport"></Polaroid>
      </div>
      <div class="col-md-6">

      </div>
    </div>
  </template>

</template>

<script>
import axios from 'axios';
import PhotoGallery from "@/components/PhotoGallery.vue";
import {API_ENDPOINT} from "@/config/config";
import Polaroid from "@/components/Polaroid.vue";

export default {
  components: {Polaroid, PhotoGallery},
 data() {
    return {
      photosData: [],
    };
  },
  computed: {
    hasId() {
      return this.$route.params.name;
    },
  },
  watch: {
    '$route.params.name': 'fetchData',
  },
  mounted() {
    this.fetchData();
    },
  methods: {
    fetchData() {
      if (this.hasId) {
        axios.get(API_ENDPOINT+`/galleries/${this.$route.params.name}`)
            .then(response => {
              this.photosData = response.data;
            })
            .catch(error => {
              console.error("Error fetching data:", error);
            });
      }
    }
  }
  };
</script>

