<template>
  <H1>{{ $t('prices') }}</H1>
  <div class="container-md" style="text-align: left">
    <H2> {{ $t('prices-title')}} </H2>
    <p> {{ $t('prices-intro')}} </p>
    <hr/>
    <H3> {{ $t('prices-explore-below')}} </H3>
    <ul>
      <li><b>{{ $t('portrait-photography')}}</b>&nbsp;:&nbsp;<span>{{ $t('prices-portrait-photography-intro')}}</span></li>
      <li><b>{{ $t('event-photography')}}</b>&nbsp;:&nbsp;<span>{{ $t('prices-event-photography-intro')}}</span></li>
      <li><b>{{ $t('wedding-photography')}}</b>&nbsp;:&nbsp;<span>{{ $t('prices-wedding-photography-intro')}}</span></li>
      <li><b>{{ $t('product-photography')}}</b>&nbsp;:&nbsp;<span>{{ $t('prices-product-photography-intro')}}</span></li>
      <li><b>{{ $t('bodybuilding-photography')}}</b>&nbsp;:&nbsp;<span>{{ $t('prices-bodybuilding-photography-intro')}}</span></li>
    </ul>

    <p> {{ $t('prices-not-sure')}} </p>
    <p> {{ $t('prices-remember')}} </p>

    <div> {{ $t('')}} </div>

    <div class="row" style="text-align: left;">
      <div class="col-12">
        <p v-html="$t('book-with-phone')"></p>
      </div>
    </div>


    <div> {{ $t('')}} </div>
  </div>
</template>
<script setup>
</script>
