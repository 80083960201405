<template>

  <div class="container-md" style="text-align: left">
  <H1>Impressum</H1>
  <address>
    <h2>PaderFoto (Farshad Afshari)</h2>
    <p>Eibenweg 13, 33100 Paderborn</p>
    <p>0177-181-1974</p>
    <p>paderfoto@web.de</p>
  </address>

  <h2>Handwerkskammer</h2>
  <p>
    Betriebs-Nr: 747335<br>
  </p>


  <h2>Umsatzsteuer-Identifikationsnummer (USt-IdNr.)</h2>
  <p> </p>


  <h2>Streitschlichtung</h2>
  <p>
    Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/main/?event=main.home2.show">https://ec.europa.eu/consumers/odr/main/?event=main.home2.show</a>.
  </p>

  <h2>Haftungsausschluss</h2>
  <p>
    Wir bemühen uns, auf dieser Website genaue und aktuelle Informationen zur Verfügung zu stellen. Jedoch können wir keine Garantie für die Vollständigkeit oder Richtigkeit aller Informationen übernehmen. Wir behalten uns das Recht vor, jederzeit und ohne vorherige Ankündigung Änderungen oder Korrekturen an den Inhalten vorzunehmen. Wir können keine Haftung für Schäden übernehmen, die sich aus der Nutzung der Informationen auf dieser Website ergeben.
  </p>

  <h2>Urheberrecht</h2>
  <p>
    Die Inhalte und Werke auf dieser Website unterliegen dem deutschen Urheberrecht. Vervielfältigung, Bearbeitung, Verbreitung und jede Art der kommerziellen Verwertung solcher Materialien außerhalb des Urheberrechtsgesetzes bedürfen der vorherigen schriftlichen Zustimmung des jeweiligen Autors oder Schöpfers. Downloads und Kopien dieser Website sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
  </p>

  <h2>Links</h2>
  <p>
    Für den Inhalt externer Websites, die von unserer Seite verlinkt sind, sind wir nicht verantwortlich. Für die Inhalte der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
  </p>

  <h2>Datenschutz</h2>
  <p>Unsere ausführliche Datenschutzerklärung finden Sie <a href="">hier</a>.</p>

  <h2>Social-Media-Links</h2>
  <ul>
    <li><a href="https://www.instagram.com/momentmaler/" target=”_blank”>Instagram</a></li>
      <li><a href="https://www.instagram.com/paderfoto/" target=”_blank”>Instagram 2</a></li>
        <li><a href="https://www.youtube.com/@farshadafshari" target=”_blank”>YouTube</a></li>
  </ul>
  </div>
</template>
<script setup>
</script>
