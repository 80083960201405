<!-- src/components/PhotoGallery.vue -->

<template>
  <div>
    <div v-for="photo in photos" :key="photo.name" class="photo-container">
      <div @click="openModal(photo)">
        <img :src="fetchPhoto(photo.thumbPhoto)" :alt="photo.name" />
      </div>
    </div>

<!--    <div v-if="selectedPhoto" class="modal">
      <span class="close" @click="closeModal">&times;</span>
      <img :src="selectedPhoto.path" :alt="selectedPhoto.name" />
      <div class="caption">{{ selectedPhoto.name }}</div>
    </div>-->

    <div v-if="selectedPhoto" class="modal">
      <span class="close" @click="closeModal">&times;</span>
      <div class="modal-content">
        <img :src="fetchPhoto(selectedPhoto.photo)" :alt="selectedPhoto.name" />
<!--        <div class="caption">{{ selectedPhoto.name }}</div>-->
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    photos: Array,
  },
  data() {
    return {
      selectedPhoto: null,
    };
  },
  methods: {
    openModal(photo) {
      console.log('Opening modal for:', photo);

      this.selectedPhoto = photo;
      console.log("this.selectedPhoto is "+this.selectedPhoto)
    },
    closeModal() {
      this.selectedPhoto = null;
    },
    fetchPhoto(photob64){
      return 'data:image/jpeg;base64,'+photob64
    }
  },
};
</script>

<style scoped>
.photo-container {
  display: inline-block;
  margin: 10px;
}

.modal {
  position: fixed;
  z-index: 1;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex; /* Use flexbox to center content */
  align-items: center;
  justify-content: center;
}

.modal-content {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: rgba(128, 128, 128, 0.8);
}

.modal img {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 60%;
  max-height: 60%;
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
  z-index: 1;
}

.caption {
  margin: 10px;
  color: #f1f1f1;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
</style>
