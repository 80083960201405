import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import App from './App.vue'
import home from "@/views/homePage.vue";
import about from "@/views/aboutMe.vue";
import contact from "@/views/contactMe.vue";
import pricing from "@/views/thePricing.vue";
import portfolio from "@/views/MyPortfolio.vue";
import register from "@/views/authRegister.vue";
import impressum from "@/views/impressumPage.vue";
import gallery from "@/views/galleryPage.vue";
import specialOffer from "@/views/specialOffer.vue";
import { createI18n } from 'vue-i18n'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"



const messages = {
    en: require('./locales/en.json'),
    de: require('./locales/de.json'),
}

const i18n = new createI18n({
    locale: 'de', // Set the default locale
    fallbackLocale: 'de', // Fallback locale
    messages,
})

const router = createRouter({
    history: createWebHashHistory(),
    routes:[
        {
            path:'/',
            name: 'Home',
            component: home ,
            meta: {
                title: 'PaderFoto - Home',
                description: ''
            }
            },
        {
            path:'/about',
            name:'About',
            component: about,
            meta: {
                title: 'PaderFoto - Home',
                description: ''
            }
            },
        {
            path:'/contact',
            name:'Contact',
            component: contact,
            meta: {
                title: 'PaderFoto - Home',
                description: ''
            }
            },
        {
            path:'/portfolio/:name?',
            name:'Portfolio',
            component: portfolio,
            meta: {
                title: 'PaderFoto - Home',
                description: ''
            }
            },
        {
            path:'/prices',
            name:'Prices',
            component: pricing,
            meta: {
                title: 'PaderFoto - Home',
                description: ''
            }
            },
        {
            path:'/register',
            name:'register',
            component: register,
            meta: {
                title: 'PaderFoto - Home',
                description: ''
            }
        },
        {
            path:'/impressum',
            name:'impressum',
            component: impressum,
            meta: {
                title: 'PaderFoto - impressum',
                description: ''
            }
        },
        {
            path:'/gallery/:name?',
            name:'gallery',
            component: gallery,
            meta: {
                title: 'PaderFoto - Gallery',
                description: ''
            }
        },
        {
            path:'/specialOffer',
            name:'Special Offer',
            component: specialOffer,
            meta: {
                title: 'PaderFoto - Special Offer 30 Minutes in Nature, 5 Edited Photos for 49 Euros ',
                description: ''
            }
        },
    ]
})

createApp(App).use(router).use(i18n).mount('#app')
