<template>
  <H1>Register</H1>
  <input type="email"
         name="email"
         v-model="email"
         placeholder="email">

  <input type="password"
         name="password"
         v-model="password"
         placeholder="password">

  <br>
  <button @click="register">
    Register
  </button>
</template>
<script>
import AuthenticationService from '@/services/AuthenticationService'

export default {
  data () {
    return {
      email: '',
      password: '',
      error: null
    }
  },
  methods: {
    async register () {
      try {
        const response = await AuthenticationService.login({
          email: this.email,
          password: this.password
        })
        this.$store.dispatch('setToken', response.data.token)
        this.$store.dispatch('setUser', response.data.user)
        this.$router.push({
          name: 'songs'
        })
      } catch (error) {
        this.error = error.response.data.error
      }
    }
  }
}
</script>