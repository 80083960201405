
<template>

  <h1>Gallery</h1>
  <template v-if="hasId">
<!--    <p>API Response: {{ apiData }}</p>-->
    <!-- Add other content based on the API response -->
    <div>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
<!--            <p v-html="$t($route.params.name+'-exp')"></p>-->
          </div>
      </div>
      </div>
      <hr>
      <GridPhotoGallery galleryID="my-test-gallery" :photos="photosData"  />
    </div>
    <hr>
  </template>
  <template v-else>
    <div class="row">
      <div class="col-sm-6">
        NO ID for Gallery
      </div>
    </div>
  </template>

</template>

<script>
import axios from 'axios';
import {API_ENDPOINT} from "@/config/config";
import GridPhotoGallery from "@/components/GridPhotoGallery.vue";

export default {
  components: {GridPhotoGallery},
 data() {
    return {
      photosData: [],
    };
  },
  computed: {
    hasId() {
      return this.$route.params.name;
    },
  },
  watch: {
    '$route.params.name': 'fetchData',
  },
  mounted() {
    this.fetchData();
    },
  methods: {
    fetchData() {
      if (this.hasId) {
        axios.get(API_ENDPOINT+`/galleries/${this.$route.params.name}`)
            .then(response => {
              this.photosData = response.data;
            })
            .catch(error => {
              console.error("Error fetching data:", error);
            });
      }
    }
  }
  };
</script>

