<template>
  <div class="container">
  <H1 v-html="$t('title-contact-us')"></H1>

  <div class="row justify-content-center">
    <div class="col-md-12">
      <div class="wrapper">
        <div class="row no-gutters">
          <div class="col-lg-6">
            <div class="contact-wrap w-100 p-md-5 p-4">
              <p class="mb-4"></p>
              <div class="row mb-4">
                <div class="col-md-4">
                  <div class="dbox w-100 d-flex align-items-start">
                    <div class="text">
                      <p><span v-html="$t('Address')"></span>: Eibenweg 13. 33100 Paderborn</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="dbox w-100 d-flex align-items-start">
                    <div class="text">
                      <p><span>Email:</span> paderfoto[at]web.de</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="dbox w-100 d-flex align-items-start">
                    <div class="text">
                      <p><span v-html="$t('phone')"></span> 0177-181-1974</p>
                    </div>
                  </div>
                </div>
              </div>
<!--              <form method="POST" id="contactForm" name="contactForm" class="contactForm" novalidate="novalidate">-->
<!--                <div class="row">-->
<!--                  <div class="col-md-12">-->
<!--                    <div class="form-group">-->
<!--                      <input type="text" class="form-control" name="name" id="name" placeholder="Name">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-md-12">-->
<!--                    <div class="form-group">-->
<!--                      <input type="email" class="form-control" name="email" id="email" placeholder="Email">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-md-12">-->
<!--                    <div class="form-group">-->
<!--                      <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject">-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-md-12">-->
<!--                    <div class="form-group">-->
<!--                      <textarea name="message" class="form-control" id="message" cols="30" rows="4" placeholder="Create a message here"></textarea>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-md-12">-->
<!--                    <div class="form-group">-->
<!--                      <input type="submit" value="Send Message" class="btn btn-primary">-->
<!--                      <div class="submitting"></div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </form>-->
              <div class="w-100 social-media mt-5">
                <h3>Follow us here</h3>
                <p>
                  <a href="https://www.instagram.com/momentmaler/" target=”_blank”>Instagram</a>|
                  <a href="https://www.instagram.com/paderfoto/" target=”_blank”>Instagram 2</a>|
                  <a href="https://www.youtube.com/@farshadafshari" target=”_blank”>YouTube</a>
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 d-flex align-items-stretch">
            <img src="@/assets/img/windowspring1.jpg"  class="info-wrap w-100 p-5 img" alt=""/>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script setup>
</script>
